.searchboxWrapper{
    margin: 40px 0;
}
.searchboxWrapper input{
    width: 100%;
}
.searchboxWrapper label{
    font-size: 0.9rem;
    font-weight: 500;
    color: #00000095;
}
.selectCategoryContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}