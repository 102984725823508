.invisibleWrapper{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #00000040;
}
.modalContainer{
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
}
.modalContainer .btnsContainer{
    display: flex;
}