.admin-page {
  position: relative;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logout {
  margin: 0;
  background-color: #832b2b;
}
.link {
  width: fit-content;
}
.link a {
  display: inline-block;
  padding: 10px;
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  background-color: #132e5f;
  border-radius: 5px;
}
label {
  margin: 10px 0;
}
/* photo container */
.photoInputs-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.singleInput {
  position: relative;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.69);
  border-radius: 10px;
}
.btn-addInput {
  padding: 10px;
  border-radius: 5px;
  background-color: #2b4a83;
  color: #fff;
  cursor: pointer;
}
.uploaded {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1.5rem;
}
.singleInput input {
  position: absolute;
  bottom: 0;
  left: 40%;
  width: 0;
  height: 0;
  opacity: 0;
}
.thumbnail {
  max-width: 160px;
  max-height: 120px;
  cursor: pointer;
}
.video-preview {
  width: 200px;
  height: 100px;
}
.removeBtn {
  position: absolute;
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
  top: -25px;
  right: -8px;
}
.photoInputs-container button {
  margin: 0;
}
/* video container */
.videoInput-container .singleInput {
  width: fit-content;
}
.cordinates-container {
  display: flex;
}
.cordinates-container input {
  width: 90%;
}
/* spinner */
.spinner-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.spinner {
  width: 100px;
  height: 100px;
  border-top: 7px solid #132e5f;
  background-color: aquamarine;
  border-radius: 50%;
  box-shadow: 28px 43px 16px 28px rgba(0, 0, 0, 0.2);
  animation: uploading 3s ease-in-out infinite;
}
.successgif {
  display: block;
  width: 30%;
  border-radius: 20px;
}
.failedgif {
  display: block;
  width: 30%;
  border-radius: 20px;
}
.multilingual {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #132e5f;
  margin: 20px 0;
  color: #fff;
  border-radius: 15px;
}
.btn-submit {
  width: 100%;
  margin-top: 50px;
}
.error-msg {
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: red;
}

/* petar css proba */
.editor-container {
  width: 100%;
  min-height: 270px;
  background-color: #fff;
  border: 3px solid #1d1c1c;
  padding: 10px;
  padding-bottom: 50px;
  margin-bottom: 30px;

  border-radius: 15px;
  color: #000;
  position: relative;
}

.editor-container:focus-within {
  border-color: blue; 
}

.editor-controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  width: calc(100% - 20px);
  text-align: center;
  padding-top: 4px;
}

.style-button img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.style-button.underline img {
  transform: scale(1.2); /* make only icon bigger */
}

.custom-select-panel {
  margin-bottom: 40px;
}

.style-button {
  display: inline-block;
  margin: 0 10px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #e2e2e2;
  border: none;
  border-radius: 4px;
}

.style-button.active {
  background-color: #2a58ac;
}

.style-button.link:hover {
  background-color: #2a58ac;
}

.select-option:hover {
  background-color: #f0f0f0;
  color: #333;
}

.checkboxForAccessibility {
  height: 20px;
  width: 20px;
  border: 1px solid rgba(0, 0, 0, 0.69);
  box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 7px;
  margin-left: 10px;
  margin-bottom: 20px;
  
}

@keyframes uploading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
