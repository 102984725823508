.locationPreviewContainer{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    align-items: center;
    border-bottom: 1px solid #00000050;
}
.btnsContainer{
    text-align: end;
}
.btn-edit{
    display: inline-block;
    width: 35px;
    margin-right: 20px;
    cursor: pointer;
}
.btn-remove{
    display: inline-block;
    width: 32px;
    cursor: pointer;
}