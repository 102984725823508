*{
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.form,
form{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-size: 1.2rem;
}
input, select {
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.69);
  box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  margin-top: 10px;
  padding: 7px;
  font-size: 1rem;
}
textarea{
  border: 1px solid rgba(0, 0, 0, 0.69);
  box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  margin-top: 10px;
  padding: 7px;
  font-size: 1rem;
  resize: none;
}
button{
  height: 45px;
  background-color: #2b4a83;
  color: #fff;
  border: transparent;
  border-radius: 10px;
  margin: 20px auto;
  font-size: 1.2rem;
  transition: 0.5s all ease;
  cursor: pointer;
}
button:hover{
  background-color: #132e5f;
}
.loadingText{
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
  background-color: #00000070;
  color: #fff;
  font-size: 2.5rem;
}